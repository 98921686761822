import styled from "styled-components";
import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faCircle } from "@fortawesome/free-solid-svg-icons";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase/firebaseConfig";
import FattiesLogo from '../../../images/fattieswhite.png'
import {ReactComponent as WhatsAppLogo} from '../../../images/icons/whatsapp.svg'
import Container from '.././styles/estilomodalsharedmobile'
import info from '../../../data/info.json'

const ServiceCross = ({delayedClosingModal, setClosedModal}) => {

    const cross1 = require('../../../images/services/cross/cross1.png')


    return ( 
        <Container>        

             <p
            onClick={() => {setClosedModal(false);delayedClosingModal()}}
            className='close-mobile-service h1-cross'
            >X</p>    
    

            <div
            className='sections-container'
            >
                <section
                className='first-section h1-cross'
                >

                <div
                className='points-div'
                >
                    <FontAwesomeIcon 
                    className='svg-selected'
                    icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                </div>

                    <img
                    rel='preload'
                    className='logo-fatties'
                    src={FattiesLogo} alt="" />
                    <p
                    className='title fatties-title'
                    >
                        <span>Cross-Selling
                            Estratégico</span></p>
                            <span></span>
                </section>

                <section
                className='h1-cross'
                >
                    <div
                    className='points-div'
                    >
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon 
                        className='svg-selected'
                        icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                 </div>

                    <p
                    className='title'
                    >Nuestro equipo de ventas trabaja en colaboración
                    con su marca para <span>maximizar el potencial</span>
                    de ventas del negocio</p>
                    <span></span>
                </section>

                <section
                className='h1-cross'
                >

                        <div
                        className='points-div'
                        >
                            <FontAwesomeIcon icon={faCircle}/>
                            <FontAwesomeIcon icon={faCircle}/>
                            <FontAwesomeIcon 
                            className='svg-selected'
                            icon={faCircle}/>
                            <FontAwesomeIcon icon={faCircle}/>
                        </div>

                    <div>
                        <img
                        rel='preload'
                        src={cross1} alt="" />
                        <div>
                        <h3>Elaboramos <span>estrategias</span>,
                                aprovechando los establecimientos
                                que forman parte del ecosistema
                                de Fatties <span>optimizando el alcance</span>
                                de nuestra cartera de clientes.
                          </h3>
                
                        </div>
                    </div>
                    <span></span>
                </section>

                <section
                className='h1-cross'
                >

                    <div
                    className='points-div'
                    >
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon 
                        className='svg-selected'
                        icon={faCircle}/>
                 </div>

                    <p
                    className='title'
                    >Para saber mas sobre nuestras
                    campañas de ventas <span>contactate</span> con
                    uno de nuestros representantes</p>
                    <a
                    onClick={logEvent(analytics, 'Service Info | Cross-Selling')}
                    href={"https://api.whatsapp.com/send?phone=" + `${info.whatsapp}` + "&text=Hola, Me gustaría hacerles una consulta con respecto al servicio de Cross-Selling Estratégico."}
                    target='_blank'
                    >Comunicate con nosotros
                    <WhatsAppLogo/>
                    </a>
                
                   <span></span>
                   
                </section>
            </div>
        

        </Container>
     );
}
 
export default ServiceCross;