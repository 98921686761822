import styled from "styled-components";

const Container = styled.div`


        .h1-calim {
            background: linear-gradient(120deg, #79cae3 1%,  #7cfad2  99%);
        }
        .h1-mp {
            background: linear-gradient(120deg, #00baff 1%,  #0a0080 99%);
        }
        .h1-tel {
            background: linear-gradient(240deg, ${props => props.theme.tertiary} 10% ,${props => props.theme.secondary} 30%);
        }
        .h1-cross {
            background: linear-gradient(50deg, ${props => props.theme.primaryOpact} 10%, ${props => props.theme.primary} 40%);
        }
 
    display: flex;
    background-color: white;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0px 0px 10px 5px rgba(100,100,100,0.5);
    
    
    @media (max-width: 768px) {
        flex-direction: row;
        overflow-y: auto;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        width: 100vw;
        height: 100vh;
 
        /* width */
        ::-webkit-scrollbar {
       display: none;
        }
    }

    .close-mobile-service {
        display: none;
        width: 100%;
        background-color: black;
        color: white;
        
        @media (max-width: 768px) {
            display: block;
            height: 10vh;
        }
    }
    
    .sect-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        padding: 5px 0;
        height: 5vh;

        @media (max-width: 768px) {
            display: none;
        }

            h3 {
                font-size: 20px;                
            }
    }

    section {
        flex-shrink: 0;
        width: 70vw;
        height: 80vh;       
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        padding: 20px;
        gap: 30px 0;
        animation-name: fade;
        animation-duration: 500ms;
        scroll-snap-align: center;
        scroll-snap-stop: always;
 
        
        @media (max-width: 1400px) {
            width: calc(70vw);
            gap: 10px 0;
        }

        @media (max-width: 1100px) {
            width: calc(85vw);
        }

        @media (max-width: 768px) {
            width: calc(100vw);
            height: 90vh;
        }

        @keyframes fade {
            0% { opacity : 0%}
            100% {  opacity : 100%}
        }

        .logo-mp {
            height: 20vh;

            @media (max-width: 1100px) {
                height: 25vh;
            }
            @media (max-width: 768px) {
                height: 20vh;
            }
        }

        .logo-calim {
            height: 9vh;

        }
        .logo-fatties {
            height: 20vh;
        }

        h1 {
            color: white;
            padding: 32px;
            width: calc(70%);
            font-size: calc(20px + 1.25vw);
            text-align: left;
            font-weight: 400;
            border-radius: 20px;

            svg {
                font-size: 20px;
                position: static;
                top: 0;
            }

            .svg {
                padding-right: 1vw;

            }            
        }

     
        .title {
            font-size: calc(24px + 1vw)!important;
            width: 90%;
            margin: 0;
            line-height: 120%;
            color: white;     

            @media (max-width: 1100px) {
                font-size: 22px;
            }

            @media (max-width: 768px) {
                width: 100%;
            }
            span {
                font-weight: 700;
            }            
        
        }

        .fatties-title {
            text-align: left;
            font-size: calc(40px + 1vw)!important;
        }


        a {
            text-decoration: none;
            color: white!important;
            background: #1da34e;
            padding: 10px 20px;
            width: 60%;
            font-size: calc(16px + 0.25vw);
            text-align: center;
            font-weight: 700;
            transition: 300ms ease all;
            border: 3px solid white;
            border-radius: 15px;

            svg {
                padding: 0 10px;
                height: 22px;
                fill: white;
            }


            @media (max-width: 768px) {
                width: 100%;
                font-size: 18px;
                padding: 10px;
            }

            &:hover {
                filter: brightness(110%);
                transform: scale(1.03)
            }
        }

        p {
            font-size: calc(20px + 0.75vw);
            width: 90%;
            color: white;

            @media (max-width: 1100px) {
                font-size: 20px;
            }
            @media (max-width: 768px) {
                font-size: 18px;
            }

            span {
                font-weight: bold;
            }
        }


        div {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 0 30px;

            @media (max-width: 768px) {
                gap: 0;
                flex-direction: column;
                height: auto;
            }

            .img-mp {               
                
                @media (max-width: 768px) {
                    height: 12vh;
                }
            }

            img {
                object-fit: contain;
                border-radius: 5%;
                height: 100%;
                width: 40%;
                padding: 5px 0;

                @media (max-width: 768px) {
                width: 90vw;
                height: calc(18vh - 10px);
                border-radius: 10px;
            }

            }

            div {
                width: 50%;
                display: flex;
                justify-content: center;
                padding-top: 2vh;
                flex-direction: column;
                height: 100%;

                @media (max-width: 768px) {
                width: 100%;
                height: 100%;
            }

                h3 {
                    width: 100%;
                    text-align: left;
                    font-size: calc(26px + 0.75vw);
                    margin: 0;
                    color: white;
                    padding-bottom: 5vh;

                    @media (max-width: 1100px) {
                        }

                    span {
                        font-weight: 700;
                    }
                    
                }
                ul {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    ol {
                        font-size: calc(18px + 0.75vw);                   
                        padding: 0;
                        margin: 0;
                        width: 100%;
                        text-align: left;
                        color: white;

                        @media (max-width: 768px) {
                            font-size: 16px;
                        }

                        svg {
                            font-size: 16px;
                            color: lightblue;
                        }

                        .svg {
                            padding-right: 10px;
                        }
                    }

                }
            }

        }
           
        .buttons {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0 30px;
            flex-direction: row;

            span, p {
                font-size: 16px;
                font-weight: 900;
                width: 3vw;
                text-align: center;
                &:hover {
                    cursor: pointer;
                    color: gray;
                }
            }
        }
    }
    .first-section {
        gap: 20px 0;


        @media (max-width: 768px) {
            gap: 40px 0;
        }
    }

    .points-div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            gap: 0 1vw;
            justify-self: flex-end;
            height: 2.5vh; 

            .svg-selected {
                font-weight: 900;
                color: white;
                font-size: 7px;
            }

            svg {
                font-size: 5px;
                color: #e0e0e0;
            }
        } 

        


`

export default Container