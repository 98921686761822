import styled from "styled-components";
import React from "react";
import MPLogo from '../../../images/mp-logo-nuevo.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faCircle } from "@fortawesome/free-solid-svg-icons";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase/firebaseConfig";
import {ReactComponent as WhatsAppLogo} from '../../../images/icons/whatsapp.svg'
import Container from '.././styles/estilomodalsharedmobile'
import info from '../../../data/info.json'


const ServiceMpMobile = ({delayedClosingModal, setClosedModal}) => {

    const qrImage = require('../../../images/services/mp/qr.png')
    const smartImage = require('../../../images/services/mp/smart.png')
    const chatImage = require('../../../images/services/mp/chat.png')

    return ( 
        
        <Container>
            <p
            onClick={() => {setClosedModal(false);delayedClosingModal()}}
            className='close-mobile-service h1-mp'
            >X</p>    

         <div
         className='sections-container'
         >

           <section
            className='first-section h1-mp'
            >

                <div
                className='points-div'
                >
                <FontAwesomeIcon 
                className='svg-selected'
                icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                </div>

                <div
                style={{display: 'flex', gap: '5vh'}}
                >
                    <img
                    rel='preload'
                    className='logo-mp'
                    src={MPLogo} alt="" />
                    <p
                    className='title'
                    >Somos Partner oficial de <span>Mercado Pago</span>, brindamos <span>asistencia y asesoramiento</span> en el uso de la plataforma</p>
                </div>
                         
                <span></span>

            </section>

            <section
            className='first-section h1-mp'
            >
                <div
                className='points-div'
                >
                    <FontAwesomeIcon 
                    icon={faCircle}/>
                    <FontAwesomeIcon 
                    className='svg-selected'
                    icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                </div>
                
                <p
                className='title'
                >Como <span>consultora certificada</span> su comercio accede <span>sin costo alguno</span> a los siguientes beneficios</p>

                <span></span>

            </section>
                      
            <section
            className='h1-mp'
            >   
                       <div
                    className='points-div'
                    >
                    <FontAwesomeIcon 
                    icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon 
                    className='svg-selected'
                    icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    </div> 

            

                <div
                >
                    <img 
                    className=''
                    rel='preload'
                    src={qrImage} alt="" />
                    <div>
                    <h3>Reposición de materiales de marca <span>sin costo</span></h3>
                        <ul>
                            <ol>
                            <FontAwesomeIcon icon={faChevronRight}/>
                            <FontAwesomeIcon 
                            className='svg'
                            icon={faChevronRight}/>   
                                Carteleria Qr</ol>
                            <ol>
                            <FontAwesomeIcon icon={faChevronRight}/>
                            <FontAwesomeIcon 
                            className='svg'
                            icon={faChevronRight}/>   
                                Rollos de papel para Point</ol>
                        </ul>
                    </div>
                </div> 

          
                    <span></span>
            </section>

            <section
            className='h1-mp'
            >                  
                        <div
                        className='points-div'
                        >
                            <FontAwesomeIcon 
                            icon={faCircle}/>
                            <FontAwesomeIcon icon={faCircle}/>
                            <FontAwesomeIcon icon={faCircle}/>
                            <FontAwesomeIcon 
                            className='svg-selected'
                            icon={faCircle}/>
                            <FontAwesomeIcon icon={faCircle}/>
                            <FontAwesomeIcon icon={faCircle}/>
                            <FontAwesomeIcon icon={faCircle}/>
                        </div>

                <div
                className='div-mp'
                >

                        <img 
                        className=''
                        rel='preload'
                        src={smartImage} alt="" />
                        <div>

                        <h3>Recambio de <span>Lectores</span></h3>
                            <ul>
                                <ol>
                                <FontAwesomeIcon icon={faChevronRight}/>
                                <FontAwesomeIcon 
                                className='svg'
                                icon={faChevronRight}/>   
                                    Garantía de por vida</ol>
                            </ul>
                        </div>

                    
                    </div>                             
                <span></span>
        
            </section>
            <section
            className='h1-mp'
            >                       
          
            <div
            className='points-div'
            >
                <FontAwesomeIcon 
                icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon 
                className='svg-selected'
                icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
            </div>   

            <div
                    className='div-mp'
                    >
                    <img 
                    className=''
                    rel='preload'
                    src={chatImage} alt="" />
                        <div>
                        <h3>Canal de atención <span>telefónica exclusiva</span></h3>
                            <ul>                      

                                <ol>
                                <FontAwesomeIcon icon={faChevronRight}/>
                                <FontAwesomeIcon 
                                className='svg'
                                icon={faChevronRight}/>                               
                                Lunes a viernes de 9 a 18h</ol>
                            </ul>

                        </div>
                    </div>  

                <span></span>   

                                               
            </section>

             <section
             className='h1-mp'
             >

              

                <div
                className='points-div'
                >
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon 
                    className='svg-selected'
                    icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                </div>   

                <h1
                className=''
                >
                    <FontAwesomeIcon icon={faChevronRight}/>
                    <FontAwesomeIcon 
                    className='svg'
                    icon={faChevronRight}/> 
                    Acceso a bonificaciones de tasas 
                    <br />
                    exclusivas para su marca</h1>
                <h1
                className=''
                >
                    <FontAwesomeIcon icon={faChevronRight}/>
                    <FontAwesomeIcon 
                    className='svg'
                    icon={faChevronRight}/> 
                    Acceso funcionalidades y 
                    <br />
                    herramientas de la plataforma</h1>   

                    <span></span>
             
 
            </section> 

             <section
             className='h1-mp'
             >  
             
             <div
                className='points-div'
                >
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon icon={faCircle}/>
                    <FontAwesomeIcon 
                    className='svg-selected'
                    icon={faCircle}/>
                </div>
             
                <p>Si tu comercio todavía no tiene un <span>consultor asignado</span>, no dude en contactarse para dar de alta el <span>programa de representación</span> de la mano de una empresa con 5 años de experiencia en el rubro</p>

                <a 
                onClick={logEvent(analytics, 'Service Info | Mercado Pago')}
                href={"https://api.whatsapp.com/send?phone=" + `${info.whatsapp}` + "&text=Hola, Me gustaría hacerles una consulta con respecto a la asignación de un consultor para el programa de representación de Mercado Pago."}
                target='_blank'
                >Comunicate con nosotros
                <WhatsAppLogo/>
                </a>

                <span></span>
        

                </section>                  
             </div>
        </Container>
     );
}
 
export default ServiceMpMobile;