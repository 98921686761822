import 'bootstrap/dist/css/bootstrap.min.css';
import ProductContainer from './styles/estilofaq'
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Faq1 = () => {

    const logowhatsapp = require('../../images/icons/whatsapp.png')

    const [show1, setShow1] = useState(false)
    const [show2, setShow2] = useState(false)
    const [show3, setShow3] = useState(false)
    const [show4, setShow4] = useState(false)
    const [show5, setShow5] = useState(false)

    return (
        <>
        <ProductContainer>
            <p>
                <a 
               className="faq-anchor"
               href="/faq">
                FAQ 
                </a> / Todo sobre Fatties</p>

                <div className="product-types1 mt-3">
                    {/* <h2
                    className="subtitle-faq"
                    >
                        Somos una comercializadora ubicada en Haedo, zona oeste de Argentina, con una trayectoria de 5 años en el mercado. 
                        Actualmente, representamos a 3000 comercios activos y trabajamos con reconocidas marcas como Mercado Pago y Calim estudio contable digital.
                    </h2> */}
                    <br />

                    <div
                    style={{width: '100%',
                    display: "flex",
                    flexDirection: 'column',
                    gap: '20px'
                    }}
                    >
                        <div>
                            
                        <h1 onClick={() => setShow1(!show1)}>Sobre la Empresa
                        {
                            !show1 &&
                            <FontAwesomeIcon className='svg' icon={faChevronDown}/>}
                        </h1>
                        {show1 &&
                        <div className="faq-hidden-text">

                       <ul>
                        <li><strong>¿Quiénes Somos?</strong>
                        <br />
                        Somos una comercializadora ubicada en Haedo, zona oeste de Argentina, con una trayectoria de 5 años en el mercado. Actualmente, representamos a 3000 comercios activos y trabajamos con reconocidas marcas como Mercado Pago y Calim estudio contable digital.
                        </li>

                        <li><strong>¿Cómo nació nuestra empresa?</strong>
                        <br /> Nuestra empresa nació con el propósito de ser el nexo entre comercios y soluciones financieras y contables, buscando siempre ofrecer los mejores servicios y herramientas.</li>


                        <li><strong>¿Por qué confiar en nosotros?</strong>                         
                        <br />
                        Contamos con una experiencia en el rubro de 5 años, junto con nuestra extensa cartera de comercios activos y alianzas con marcas líderes, avala nuestro compromiso y seriedad en el mercado.</li>

                       </ul>
                       

                        </div>
                        }
                        </div>

                        <div>
                            <h1 onClick={() => setShow2(!show2)}>Sobre los Servicios
                           { !show2 && <FontAwesomeIcon className='svg' icon={faChevronDown}/>}
                            </h1>
                            {   show2 &&
                        <div className="faq-hidden-text">
                        <ul>
                        <li><strong>¿Con qué marcas trabajan oficialmente? </strong>
                            <br />
                            Trabajamos oficialmente con Mercado Pago como procesadora de pagos y con Calim estudio contable digital de prestigio.
                        </li>

                        <li><strong>¿Cómo puedo sumar mi comercio a su cartera? </strong>
                        <br />
                        ¡Nos encantaría trabajar contigo! Por favor, dirígete hacia abajo en esta misma sección  de nuestra página web y uno de nuestros representantes se pondrá en contacto contigo a la brevedad.</li>


                       </ul>

                            </div>
                            }
                        </div>
                        

                        <div>                            
                        <h1 onClick={() => setShow3(!show3)}>Sobre Seguridad y Transparencia
                        {!show3 && <FontAwesomeIcon className='svg' icon={faChevronDown}/>}
                        </h1>
                        {
                            show3 &&
                            <div className="faq-hidden-text">

                             <ul>
                        <li><strong>¿Cómo garantizan la seguridad de mis transacciones?</strong> 
                        <br />
                         La seguridad de tus transacciones es nuestra prioridad. Por ello, trabajamos con un programa especial y selectivo que se llama Tu Consultor. Respaldamos la seguridad de tus ventas realizadas por Point y Codigo QR (Mercado Pago)
                        </li>

                        <li><strong>¿Tienen algún sistema de soporte para dudas o problemas?</strong>                         
                        <br />
                        Sí, contamos con un equipo de soporte dedicado para resolver todas tus dudas o consultas. Puedes contactarnos a través de nuestra página web o al número de contacto proporcionado.</li>


                       </ul>
                            </div>
                        }
                        </div>  

                        <div>                            
                        <h1 onClick={() => setShow4(!show4)}>¿Por qué confiar en nosotros?
                        {!show4 && <FontAwesomeIcon className='svg' icon={faChevronDown}/>}
                        </h1>
                        {
                            show4 &&
                            <div className="faq-hidden-text">
                           <ul>                  

                        <li><strong>¿Qué diferencia a sus servicios de los demás? </strong> 
                        <br />
                        Ofrecemos una propuesta integral. No solo facilitamos soluciones financieras, sino que también brindamos servicios contables de la mano de Calim, y opciones en áreas de seguridad y salud. Estamos comprometidos con satisfacer las diversas necesidades de negocios, comercios y empresas. Si te interesa nos podes contactar a través de nuestra pagina web o a nuestro numero de contacto</li>


                       </ul>
                            </div>
                        }
                        </div>           
                    </div>
                </div>
        </ProductContainer>
        </>
    );
}
 
export default Faq1;
