import styled from "styled-components";
import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft, faCircle } from "@fortawesome/free-solid-svg-icons";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase/firebaseConfig";
import FattiesLogo from '../../images/fattieswhite.png'
import {ReactComponent as WhatsAppLogo} from '../../images/icons/whatsapp.svg'
import Container from './styles/estilomodalshared'
import info from '../../data/info.json'

const ServiceTel = ({show, show2, show3, show4, show5, forward, backwards, delayedClosingModal, setClosedModal}) => {

    const tel1 = require('../../images/services/tel/tel2.png')
    const tel2 = require('../../images/services/tel/tel1.png')

    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
         // Clean up event listener when component is unmounted
         document.removeEventListener('keydown', closeOnEscape);
        if (window.innerWidth < 765) {
            setIsMobile(true)
        }
    }, [])

    const closeOnEscape = (event) => {
        if (event.key === 'Escape') {
          setClosedModal(false)
          delayedClosingModal()
        }
      };
      
      // Attach the event listener on component mount
      document.addEventListener('keydown', closeOnEscape);
      

    return ( 
        <Container>
            
            <section
            style={{ display: show ? 'flex' : 'none' }}
            className='first-section h1-tel'
            >
                   <div
                className='points-div'
                >
            
                <FontAwesomeIcon 
                className='svg-selected'
                icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                </div>
                <img 
                rel='preload'
                className='logo-fatties'
                src={FattiesLogo} alt="" />

                <p
                className='title fatties-title'
                >Centro de 
                    <br />
                    <span>atención telefónica</span></p>
            <span className="empty"></span>

            </section>

            <section
            className='h1-tel'
            style={{ display: show2 ? 'flex' : 'none' }}
            >
                     <div
                className='points-div'
                >
            
                    <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon 
                className='svg-selected'
                icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                </div>
                <p
                className='title'
                >Como <span>consultora certificada</span> su comercio accede <span>sin costo alguno</span> a los siguientes beneficios</p>
                <span className="empty"></span>
            </section>

            <section
            className='h1-tel'
            style={{ display: show3 ? 'flex' : 'none' }}
            >
                    <div
                className='points-div'
                >
            
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon 
                className='svg-selected'
                icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                </div>
                <div>
                    <img
                    rel='preload'
                    src={tel1} alt="" />
                    <div>
                    <h3>Nuestro centro de <span>atención telefónica</span>  
                        utiliza tecnología de vanguardia
                        y <span>procesos eficientes</span> para garantizar
                        una comunicación <span>fluida y eficaz.</span>
                      </h3>
                                
                    </div>
                </div>

            </section>

            <section
           style={{ display: show4 ? 'flex' : 'none' }}
           className='h1-tel'
            >
                     <div
                className='points-div'
                >
            
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon 
                className='svg-selected'
                icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                </div>
                <div>
                        <img
                        rel='preload'
                        src={tel2} alt="" />
                        <div>
                        <h3>Recambio de <span>Lectores</span></h3>
                            <ul>
                                <ol>
                                <FontAwesomeIcon icon={faChevronRight}/>
                                <FontAwesomeIcon
                                className='svg'
                                icon={faChevronRight}/>
                                    Garantía de por vida</ol>
                            </ul>
                        </div>
                    </div>
            </section>

            <section
            className='h1-tel'
            style={{ display: show5 ? 'flex' : 'none' }}
            >
                     <div
                className='points-div'
                >
            
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon 
                className='svg-selected'
                icon={faCircle}/>
                
                </div>
                <p>Si estás interesado en sumar el servicio de
                    atención telefónica para tu marca
                    no dudes en <span>contactarnos</span></p>

                <a 
                onClick={logEvent(analytics, 'Service Info | Telphone Service')}
                href={"https://api.whatsapp.com/send?phone=" + `${info.whatsapp}` +"&text=Hola, Me gustaría hacerles una consulta con respecto al servicio de Atención Telefónica."}
                target='_blank'
                >Comunicate con nosotros
               <WhatsAppLogo/>

                </a>
                <span className="empty"></span>
              

                {/* <div
                className='buttons'
                >
                    <p
                    onClick={() => {setShow3(false); setShow2(true)}}
                    >2</p>                      
                    <span></span>
                </div> */}
            </section>  

            <div
            className='sect-nav'
            >
            {show === true ? 
            <span
            className='movers'
            ></span>
            :
            <p
            className='movers movers-svg'
            onClick={backwards}
            >
                <FontAwesomeIcon icon={faChevronLeft}/>
                </p>}

                <h2
                onClick={() => {delayedClosingModal(); setClosedModal(false)}}
                >X</h2>


            {
            show5 === true ?
            <span
            className='movers'
            ></span> 
            :
            <p
            className='movers movers-svg'
            onClick={forward}
            >
                <FontAwesomeIcon icon={faChevronRight}/>
            </p>}
                </div>              

        </Container>
     );
}
 
export default ServiceTel;
