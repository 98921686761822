import styled from "styled-components";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faCircle } from "@fortawesome/free-solid-svg-icons";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase/firebaseConfig";
import FattiesLogo from '../../../images/fattieswhite.png'
import {ReactComponent as WhatsAppLogo} from '../../../images/icons/whatsapp.svg'
import Container from '.././styles/estilomodalsharedmobile'
import info from '../../../data/info.json'

const ServiceTelMobile = ({delayedClosingModal, setClosedModal}) => {

    const tel1 = require('../../../images/services/tel/tel2.png')
    const tel2 = require('../../../images/services/tel/tel1.png')
    
    return ( 
        <Container>

            <p
            onClick={() => {setClosedModal(false);delayedClosingModal()}}
            className='close-mobile-service h1-tel'
            >X</p>    

            
         <div
         className='sections-container'
         >
                <section
                className='first-section h1-tel'
                >

                    <div
                    className='points-div'
                    >
                        <FontAwesomeIcon 
                        className='svg-selected'
                        icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                </div>

                    <img
                    rel='preload'
                    className='logo-fatties'
                    src={FattiesLogo} alt="" />
                    <p
                    className='title fatties-title'
                    >Centro de
                        <br />
                        <span>atención telefónica</span></p>

                        <span></span>
                </section>
                <section
                className='h1-tel'
                >

                    <div
                    className='points-div'
                    >
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon 
                        className='svg-selected'
                        icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                </div>

                    <p
                    className='title'
                    >Como <span>consultora certificada</span> su comercio accede <span>sin costo alguno</span> a los siguientes beneficios</p>

                    <span></span>

                </section>

                <section
                className='h1-tel'
                >

                    <div
                    className='points-div'
                    >
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon 
                        className='svg-selected'
                        icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                </div>

                    <div>
                        <img
                        rel='preload'
                        src={tel1} alt="" />
                        <div>
                        <h3>Nuestro centro de <span>atención telefónica</span>
                            utiliza tecnología de vanguardia
                            y <span>procesos eficientes</span> para garantizar
                            una comunicación <span>fluida y eficaz.</span>
                          </h3>
                
                        </div>
                    </div>

                    <span></span>

                </section>
                <section
                className='h1-tel'
                >

                    <div
                    className='points-div'
                    >
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon 
                        className='svg-selected'
                        icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                </div>
                    <div>
                            <img
                            rel='preload'
                            src={tel2} alt="" />
                            <div>
                            <h3>Recambio de <span>Lectores</span></h3>
                                <ul>
                                    <ol>
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                    <FontAwesomeIcon
                                    className='svg'
                                    icon={faChevronRight}/>
                                        Garantía de por vida</ol>
                                </ul>
                            </div>
                        </div>
                        <span></span>
                </section>
                <section
                className='h1-tel'
                >

                    <div
                    className='points-div'
                    >
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon icon={faCircle}/>
                        <FontAwesomeIcon 
                        className='svg-selected'
                        icon={faCircle}/>
                </div>
                    <p>Si estás interesado en sumar el servicio de
                        atención telefónica para tu marca
                        no dudes en <span>contactarnos</span></p>
                    <a
                    onClick={logEvent(analytics, 'Service Info | Telphone Service')}
                    href={"https://api.whatsapp.com/send?phone=" + `${info.whatsapp}` +"&text=Hola, Me gustaría hacerles una consulta con respecto al servicio de Atención Telefónica."}
                    target='_blank'
                    >Comunicate con nosotros
                   <WhatsAppLogo/>
                    </a>
                
                <span></span>
                
                </section>
            </div>         

        </Container>
     );
}
 
export default ServiceTelMobile;
